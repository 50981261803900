import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
// import echarts from 'echarts'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
const componentName = [ Loading ]
Vue.prototype.axios = axios
// Vue.prototype.$echarts = echarts
Vue.use(Vant);

Vue.config.productionTip = false

componentName.some(comName => {
  Vue.use(comName)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
